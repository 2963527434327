.floating-buttons {
  position: fixed;
  bottom: 20px; /* Ajusta la posición vertical desde el fondo */
  right: 20px; /* Ajusta la posición horizontal desde la derecha */
  display: flex;
  flex-direction: row; /* Coloca los botones en una fila */
  align-items: center; /* Alinea los botones verticalmente */
  z-index: 1001; /* Asegúrate de que estén por encima de otros elementos */
  gap: 15px; /* Espacio entre los botones */
}

.whatsapp-button {
  background: white; /* Fondo blanco para el icono de WhatsApp */
  border-radius: 50%; /* Para que el fondo sea circular */
  padding: 10px; /* Espacio alrededor del icono */
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para el botón */
}

.whatsapp-button, .scroll-to-top-button {
  background: white; /* Fondo blanco para ambos botones */
  border-radius: 50%; /* Para que ambos botones sean circulares */
  padding: 10px; /* Espacio alrededor de los íconos */
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para ambos botones */
  width: 60px; /* Tamaño igual para ambos botones */
  height: 60px; /* Tamaño igual para ambos botones */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease; /* Transición suave para mostrar/ocultar */
}

.floating-language-buttons {
  display: flex;
  flex-direction: row; /* Coloca los botones uno al lado del otro */
  justify-content: center; /* Centra los botones horizontalmente */
  align-items: center;
  position: fixed;
  top: 2px; /* Más cerca del borde superior */
  right: 50%; /* Ajusta para centrar en la página */
  transform: translateX(50%); /* Ajusta para centrar en la página */
  z-index: 1001;
  gap: 0; /* Elimina cualquier espacio entre los botones */
  border-radius: 5px; /* Bordes redondeados para todo el conjunto */
  overflow: hidden; /* Evita que los bordes sobresalgan */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

.floating-language-buttons button {
  background: #f0f0f0; /* Fondo inicial claro */
  border: none;
  cursor: pointer;
  padding: 6px 12px; /* Reducido el padding para hacer los botones más pequeños */
  font-size: 14px; /* Ajustado el tamaño de la fuente para botones más pequeños */
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
  flex: 1; /* Asegura que ambos botones ocupen el mismo espacio */
  border-right: 1px solid #ddd; /* Borde entre botones */
}

.floating-language-buttons button:last-child {
  border-right: none; /* Elimina el borde del último botón */
}

.floating-language-buttons button.active {
  background-color: var(--color1); /* Usando la variable de color azul de tu global */
  color: #000; /* Cambia el color del texto del botón activo */
}

.floating-language-buttons button:hover {
  background-color: var(--color3); /* Fondo al hacer hover */
}

.floating-language-buttons button:focus {
  outline: none; /* Elimina el borde predeterminado al enfocar */
}



.scroll-to-top-button {
  background: white; /* Fondo blanco para la flecha */
  border-radius: 50%; /* Para que el fondo sea circular */
  padding: 10px; /* Espacio alrededor del icono */
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px; /* Posición vertical desde el fondo */
  left: 20px; /* Posición horizontal desde la izquierda */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para el botón */
  z-index: 1001; /* Asegúrate de que esté por encima de otros elementos */
  transition: opacity 0.3s ease; /* Transición suave para mostrar/ocultar */
  opacity: 0; /* Inicialmente oculto */
}

.scroll-to-top-button.visible {
  opacity: 1; /* Mostrar cuando es visible */
}
